const minWidthDefault = 200;

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    description: 'Provider ID',
    minWidth: 55
  },
  {
    field: 'name',
    headerName: 'Name',
    description: 'Name',
    minWidth: minWidthDefault
  },
  {
    field: 'description',
    headerName: 'Description',
    description: 'Description',
    minWidth: 250
  },
  {
    field: 'cron',
    headerName: 'Cron Schedule',
    description: 'Cron Schedule',
    minWidth: 150
  },
  {
    field: 'owner_id',
    headerName: 'Owner ID',
    description: 'Owner ID',
    minWidth: 75
  },
  {
    field: 'owner_name',
    headerName: 'Owner Name',
    description: 'Owner Name',
    minWidth: minWidthDefault
  },
  {
    field: 'owner_description',
    headerName: 'Owner Description',
    description: 'Owner Description',
    minWidth: minWidthDefault
  },
  {
    field: 'owner_timezone',
    headerName: 'Owner Timezone',
    description: 'Owner Timezone',
    minWidth: minWidthDefault
  },
  {
    field: 'provider_id',
    headerName: 'Provider ID',
    description: 'Provider ID',
    minWidth: 75
  },
  {
    field: 'provider_name',
    headerName: 'Provider Name',
    description: 'Provider Name',
    minWidth: minWidthDefault
  },
  {
    field: 'provider_description',
    headerName: 'Provider Description',
    description: 'Provider Description',
    minWidth: minWidthDefault
  },
  {
    field: 'consumer_names',
    headerName: 'Consumers',
    description: 'Consumers',
    minWidth: 125
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    description: 'Created By',
    minWidth: 175
  },
  {
    field: 'created_datetime',
    headerName: 'Created Datetime',
    description: 'Created Datetime',
    minWidth: 225
  },
  {
    field: 'updated_by',
    headerName: 'Updated By',
    description: 'Updated By',
    minWidth: 175
  },
  {
    field: 'updated_datetime',
    headerName: 'Updated Datetime',
    description: 'Updated Datetime',
    minWidth: 225
  },
  {
    field: 'partition_configs',
    headerName: 'Partition Configs',
    description: 'Partition Configs',
    minWidth: minWidthDefault,
    renderCell: (params) => {
      return (params.row.partition_configs) ? JSON.stringify(params.row.partition_configs) : '';
    }
  }
];

export {columns};