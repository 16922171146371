const columns = [
  {
    field: 'id',
    headerName: 'ID',
    description: 'Owner ID',
    minWidth: 75
  },
  {
    field: 'name',
    headerName: 'Name',
    description: 'Name',
    minWidth: 250
  },
  {
    field: 'description',
    headerName: 'Description',
    description: 'Description',
    minWidth: 200
  },
  {
    field: 'timezone',
    headerName: 'Time Zone',
    description: 'Time Zone',
    minWidth: 175
  },
  {
    field: 'business_id',
    headerName: 'Business ID',
    description: 'Business ID',
    minWidth: 150
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    description: 'Created By',
    minWidth: 175
  },
  {
    field: 'created_datetime',
    headerName: 'Created Datetime',
    description: 'Created Datetime',
    minWidth: 225
  },
  {
    field: 'updated_by',
    headerName: 'Updated By',
    description: 'Updated By',
    minWidth: 175
  },
  {
    field: 'updated_datetime',
    headerName: 'Updated Datetime',
    description: 'Updated Datetime',
    minWidth: 225
  },
  {
    field: 'multi_site',
    headerName: 'Multi Site',
    description: 'Multi Site Enabled Flag',
    minWidth: 100,
    valueGetter: (params) => params.value === 1 ? 'true' : 'false'
  }
];

export {columns};