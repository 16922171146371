import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import BusinessService from "../../../apis/BusinessService";
import ConsumersService from "../../../apis/ConsumerService";
import OwnerConsumersService from "../../../apis/OwnerConsumersService";
import OwnersService from "../../../apis/OwnersService";
import OwnerAssociationsService from "../../../apis/OwnerAssociationService";
import RocksetService from "../../../apis/RocksetService";
import Auth from "../../../auth/Auth";

const OwnerEditDialog = ({ open, setOpen, rowEditing, mode, handleError, parentOwnerId }) => {
  const [selectedConsumers, setSelectedConsumers] = useState([]);

  const queryClient = useQueryClient();
  const getTimezones = useQuery(
    "getTimezones",
    async () => {
      const data = await RocksetService.executeQuery("timezones");
      const sortedNames = data.map((rec) => rec.name).sort();
      return data
        .sort(
          (a, b) => sortedNames.indexOf(a.name) - sortedNames.indexOf(b.name)
        )
        .map((tz) => ({ id: tz._id, ...tz }));
    },
    {
      onError: (error) => {
        handleError(error);
      },
      retry: false,
      staleTime: Infinity,
    }
  );

  const getBusinesses = useQuery(
    "getBusinesses",
    async () => BusinessService.getAll(),
    {
      onError: (error) => {
        handleError(error);
      },
      retry: false,
      staleTime: Infinity,
    }
  );

  const consumersQuery = useQuery(
    ["allConsumers"],
    async () => ConsumersService.getAll(),
    { cacheTime: Infinity }
  );

  const consumersForOwner = useQuery(
    ["consumers-by-owner", rowEditing?.id],
    () => OwnerConsumersService.get(rowEditing?.id),
    { enabled: !!rowEditing?.id }
  );

  const form = useForm({
    name: rowEditing?.name || "",
    description: rowEditing?.description || "",
    business_id: rowEditing?.businessId || "",
    timezone: rowEditing?.timezone
      ? getTimezones.data?.find((tz) => tz.name === rowEditing?.timezone)
          ?.name || ""
      : "",
  });

  useEffect(() => {
    if (getTimezones.isSuccess) {
      const formVals = {
        name: rowEditing?.name || "",
        description: rowEditing?.description || "",
        business_id: rowEditing?.business_id || "",
        timezone: rowEditing?.timezone
          ? getTimezones.data?.find((tz) => tz.name === rowEditing?.timezone)
              ?.name || ""
          : "",
        multi_site: rowEditing?.multi_site || 0,
      };
      form.reset(formVals);
      if (!rowEditing) setSelectedConsumers([]);
    }
  }, [rowEditing?.id, getTimezones.data]);

  useEffect(() => {
    if (consumersQuery.data?.length) {
      setSelectedConsumers(
        consumersQuery.data.filter(
          (x) =>
            consumersForOwner.data?.find((y) => x.id === y.consumer_id) !==
            undefined
        )
      );
    }
  }, [consumersQuery.data?.length, consumersForOwner.data]);

  const updateConsumers = useMutation(async (ownerId) => {
    const ownerConsumersFromDb = consumersForOwner.data;
    const selectedIds = selectedConsumers.map((consumer) => consumer.id);

    const toDeletePromises =
      ownerConsumersFromDb
        ?.filter((consumer) => !selectedIds.includes(consumer.consumer_id))
        .map((x) => OwnerConsumersService.del(ownerId, x.consumer_id)) || [];
    const toAddPromises = selectedIds
      .filter((consumerId) =>
        ownerConsumersFromDb !== undefined
          ? !ownerConsumersFromDb.some(
              (dbConsumer) => dbConsumer.consumer_id === consumerId
            )
          : true
      )
      .map((x) => OwnerConsumersService.post(ownerId, x));

    await Promise.all(toDeletePromises.concat(toAddPromises));
    queryClient.invalidateQueries("consumers-by-owner");
    setOpen(false);
  });

  const createOwnerAssociation = async (parentId, childId) => {
    try {
      const user = await Auth.getUser();
      const associationData = {
        parent_owner_id: parentId,
        child_owner_id: childId,
        updated_by: user
      };
      return await OwnerAssociationsService.post(associationData);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const postOwner = useMutation(
    "postOwner",
    async (data) => OwnersService.post(data),
    {
      onSuccess: async (res) => {
        await queryClient.invalidateQueries("getOwners");

        if (parentOwnerId) {
          await createOwnerAssociation(parentOwnerId, res.id);
        }
        updateConsumers.mutate(res.id);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const putOwner = useMutation(
    "putOwner",
    async (data) => OwnersService.put(rowEditing.id, data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("getOwners");
        updateConsumers.mutate(rowEditing.id);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const handleCreateSubmit = async () => {
    const values = form.getValues();
    const body = {
      name: values.name,
      description: values.description || undefined,
      business_id: values.business_id || undefined,
      timezone: values.timezone,
      multi_site: values.multi_site || 0, 
      created_by: await Auth.getUser(),
      updated_by: await Auth.getUser(),
    };
    postOwner.mutate(body);
    // SET SPINNER
  };

  const handleEditSubmit = async () => {
    const values = form.getValues();

    const _values = JSON.parse(JSON.stringify(values));
    _values.description = values.description || undefined;
    _values.timezone = _values.timezone || undefined;
    _values.business_id = _values.business_id || undefined;

    const changed = [];
    for (const field in _values) {
      if (_values[field] !== rowEditing[field]) {
        changed.push([field, _values[field]]);
      }
    }

    if (changed.length === 0) {
      setOpen(false);
      return;
    }

    changed.push(["updated_by", await Auth.getUser()]);
    changed.push(["id", rowEditing.id]);
    const body = Object.fromEntries(changed);

    putOwner.mutate(body);

    // SET SPINNER
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    if (mode === "edit") {
      handleEditSubmit();
    } else if (mode === "create") {
      handleCreateSubmit();
    } else {
      console.error("Invalid edit type");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <DialogTitle id="alert-dialog-title">
        {mode === "edit"
          ? `Edit Owner ${rowEditing.id}`
          : mode === "create"
          ? "Create Owner"
          : ""}
      </DialogTitle>
      <div style={{ margin: "2%" }}>
        <Grid container spacing={2}>
          {/* ##########################[ Row 1 ]############################## */}
          <Grid item xs={6}>
            <Controller
              name="name"
              control={form.control}
              render={({ field }) => (
                <TextField
                  name="name"
                  label="Name"
                  value={field.value || ""}
                  fullWidth
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          {/* ##########################[ Row 2 ]############################## */}
          <Grid item xs={8}>
            <Controller
              name="name"
              control={form.control}
              render={({ field }) => (
                <TextField
                  name="description"
                  label="Description"
                  value={field.value}
                  fullWidth
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={4} />
          {/* ##########################[ Row 3 ]############################## */}
          <Grid item xs={6}>
            <Controller
              name="business_id"
              control={form.control}
              render={({ field }) => (
                <FormControl sx={{ width: 300 }}>
                  <InputLabel id="biz-id-label">Business ID</InputLabel>
                  <Select
                    labelId="biz-id-label"
                    id="bix-id-select"
                    label="Business ID"
                    value={field.value}
                    onChange={field.onChange}
                  >
                    {getBusinesses.data?.map((business) => (
                      <MenuItem key={business.id} value={business.id}>
                        <ListItemText primary={business.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={4} />
          {/* ##########################[ Row 4 ]############################## */}
          <Grid item xs={6}>
            <Controller
              name="timezone"
              control={form.control}
              render={({ field }) => (
                <FormControl sx={{ width: 300 }}>
                  <InputLabel id="timezone-label">Time Zone</InputLabel>
                  <Select
                    labelId="timezone-label"
                    label="Time Zone"
                    id="timezone-select"
                    value={field.value}
                    onChange={field.onChange}
                  >
                    {getTimezones.data.map((tz) => (
                      <MenuItem key={tz.id} value={tz.name}>
                        <ListItemText primary={tz.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={4} />
          {/* ##########################[ Row 5 ]############################## */}
          <Grid item xs={6}>
            <FormControl sx={{ width: 300 }}>
              <InputLabel id="consumers-label">Consumers</InputLabel>
              <Select
                labelId="consumers-label"
                id="consumers-multi-select"
                label="Consumers"
                multiple
                value={selectedConsumers}
                onChange={(e) => {
                  setSelectedConsumers(e.target.value);
                }}
                input={<OutlinedInput label="Consumer" />}
                renderValue={(selected) =>
                  selected?.map((x) => x.name).join(", ")
                }
              >
                {consumersQuery.data?.map((consumer) => (
                  <MenuItem key={consumer.id} value={consumer}>
                    <Checkbox
                      checked={
                        selectedConsumers?.find(
                          (x) => x.name === consumer.name
                        ) !== undefined
                      }
                    />
                    <ListItemText primary={consumer.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} />
          {/* ##########################[ Row 6 - Multi Site ]############################## */}
          <Grid item xs={6}>
            <Controller
              name="multi_site"
              control={form.control}
              defaultValue={0}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value === 1}
                      onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                    />
                  }
                  label="Multi Site"
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          {/* ##########################[ Submit/Cancel Row ]############################## */}
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="primary"
                disabled={
                  postOwner.isLoading ||
                  putOwner.isLoading ||
                  updateConsumers.isLoading
                }
                onClick={handleClose}
                size="large"
                type="button"
                variant="outlined"
              >
                Cancel
              </Button>
              &nbsp;
              <Button
                color="primary"
                disabled={
                  postOwner.isLoading ||
                  putOwner.isLoading ||
                  updateConsumers.isLoading
                }
                size="large"
                variant="contained"
                onClick={handleSubmit}
              >
                Save
                {(postOwner.isLoading || putOwner.isLoading) && (
                  <CircularProgress size={15} sx={{ ml: "8px" }} />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

export default OwnerEditDialog;