import React, { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import EditIcon from "@mui/icons-material/Edit";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, LinearProgress, Paper, Tooltip } from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import Canvas from "../../navigation/canvas/Canvas";
import ErrorPopup from "../../common/ErrorPopup";
import IyoAuth from "../../../auth/Auth";
import OwnerEditDialog from "./OwnerEditDialog";
import OwnersService from "../../../apis/OwnersService";
import OwnerAssociationsService from "../../../apis/OwnerAssociationService";
import TableToolbar from "../../common/TableToolbar";
import { columns } from "./columns";

const Owners = ({}) => {
  // ####################[ Setup and Initialization ]####################
  const queryClient = useQueryClient();

  const initializeColumns = (initialColumns) => {
    return initialColumns.concat({
      field: "actions",
      headerName: "Actions",
      description: "Actions",
      minWidth: 175,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              onClick={(event) => handleEdit(event, params.row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          {params.row.multi_site === 1 && (
            <Tooltip title="Add Location">
              <IconButton
                color="primary"
                onClick={(event) => handleAddLocation(event, params.row)}
              >
                <AddLocationIcon />
              </IconButton>
            </Tooltip>
          )}
          {params.row.disabled ? (
            <Tooltip title="Enable">
              <IconButton
                color="secondary"
                onClick={(event) => handleDisableSubmit(event, params.row)}
              >
                <VisibilityOffIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Disable">
              <IconButton
                color="primary"
                onClick={(event) => handleDisableSubmit(event, params.row)}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    });
  };

  // ####################[ State Definitions ]####################
  const [businessId, setBusinessId] = useState(null);
  const [cols, setCols] = useState(initializeColumns(columns));
  const [editOpen, setEditOpen] = useState(false);
  const [rowEditing, setRowEditing] = useState([]);
  const [errDisplayText, setErrDisplayText] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [editMode, setEditMode] = useState("");
  const [timezoneName, setTimezoneName] = useState(null);
  const [parentOwnerId, setParentOwnerId] = useState(null);

  // ####################[ Query Definitions ]####################
  const getOwners = useQuery(
    "getOwners",
    async () => {
      return await OwnersService.getAll();
    },
    {
      onError: (error) => {
        handleError(error);
      },
      retry: false,
      staleTime: Infinity,
    }
  );

  const getOwnerAssociations = useQuery(
    "getOwnerAssociations",
    async () => {
      return await OwnerAssociationsService.getAll();
    },
    {
      onError: (error) => {
        handleError(error);
      },
      staleTime: Infinity,
    }
  );

  const postOwner = useMutation(
    "postOwner",
    async (data) => {
      return await OwnersService.post(data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("getOwners");
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const putOwner = useMutation(
    "putOwner",
    async (data) => {
      return await OwnersService.put(rowEditing.id, data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("getOwners");
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  // ####################[ Event Handlers ]####################
  const handleCreate = async () => {
    setEditMode("create");
    setRowEditing(null);
    setBusinessId(null);
    setTimezoneName(null);
    setParentOwnerId(null);
    setEditOpen(true);
  };

  const handleAddLocation = async (event, row) => {
    setEditMode("create");
    setRowEditing(null);
    setBusinessId(null);
    setTimezoneName(null);
    setParentOwnerId(row.id);
    setEditOpen(true);
  };

  const handleDisableSubmit = async (event, row) => {
    setRowEditing(row);
    const body = {
      id: row.id,
      disabled: row.disabled ? 0 : 1,
      updated_by: await IyoAuth.getUser(),
    };
    putOwner.mutate(body);
  };

  const handleEdit = async (event, row) => {
    setEditMode("edit");
    setRowEditing(row);
    setBusinessId(row.business_id);
    setTimezoneName(row.timezone);
    setEditOpen(true);
  };

  const handleError = (error) => {
    setErrorOpen(false);
    setErrDisplayText(
      `${error.message}: ` +
        `${
          error.response
            ? JSON.stringify(error.response?.data)
            : error.toString().slice(0, 200)
        }`
    );
    setErrorOpen(true);

    console.error(
      `${error.message}: ${JSON.stringify(error.response?.data || error)}`
    );
  };

  const refreshTable = async () => {
    console.info("Refreshing table");
    await queryClient.invalidateQueries("getOwners");
  };

  // ####################[ The Component ]####################
  let childToParent = {};
  getOwnerAssociations.data?.forEach((assoc) => {
    childToParent[assoc.child_owner_id] = assoc.parent_owner_id;
  });

  const ownersMap = (getOwners.data || []).reduce((acc, owner) => {
    acc[owner.id] = owner;
    return acc;
  }, {});

  function buildPath(ownerId) {
    const path = [];
    let currentId = ownerId;
    while (currentId) {
      const currentOwner = ownersMap[currentId];
      if (!currentOwner) break;
      path.unshift(currentOwner.name);
      currentId = childToParent[currentId];
    }
    return path;
  }

  const rows = (getOwners.data || []).map((o) => ({
    ...o,
    path: buildPath(o.id),
  }));

  return (
    <Box>
      <Canvas appName="nGEST" title="Owners">
        <TableToolbar editHandler={handleCreate} onRefresh={refreshTable} />
        <Paper sx={{ height: "77vh", "& .disabled": { color: "#b2b29b" } }}>
          <DataGridPro
            columns={cols}
            rows={rows}
            getRowClassName={(params) =>
              params.row.disabled ? "disabled" : ""
            }
            slots={{ loadingOverlay: LinearProgress, toolbar: GridToolbar }}
            loading={getOwners.isLoading}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  created_by: false,
                  created_datetime: false,
                  updated_by: false,
                  updated_datetime: false,
                },
              },
            }}
            density="compact"
            treeData
            getTreeDataPath={(row) => row.path}
            groupingColDef={{ field: "name", headerName: "Name", minWidth: 250 }}
          />
        </Paper>
      </Canvas>
      <OwnerEditDialog
        open={editOpen}
        setOpen={setEditOpen}
        rowEditing={rowEditing}
        mode={editMode}
        handleError={handleError}
        parentOwnerId={parentOwnerId}
      />
      <ErrorPopup
        open={errorOpen}
        setOpen={setErrorOpen}
        displayText={errDisplayText}
      />
    </Box>
  );
};

export default Owners;
