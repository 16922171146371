import axios from 'axios';
import {fetchConfig} from '../util/util';

const HOST_URL = fetchConfig('hostUrl')

const getOneByParentId = async (ownerId) => {
    return (await axios.get(`${HOST_URL}/owner-associations/?parent_owner_id=${ownerId}`)).data;
};

const getAll = async () => {
    return (await axios.get(`${HOST_URL}/owner-associations`)).data;
}

const post = async (data) => {
    return (await axios.post(`${HOST_URL}/owner-associations`, data)).data;
}

const put = async (data) => {
    return (await axios.put(`${HOST_URL}/owner-associations`, data)).data;
}

export default {getOneByParentId, getAll, post, put};