import {useQuery} from 'react-query';
import ConsumerService from '../../../../apis/ConsumerService';
import OwnersService from '../../../../apis/OwnersService';
import ProviderService from '../../../../apis/ProviderService';
import PageHelpers from '../helpers';
import DialogHelpers from './helpers';

const useConsumerQuery = (method, setState) => {
  switch (method) {
    case 'GET':
      return useQuery('getConsumers', async () => {
        return await ConsumerService.getAll();
      },
      {
        onError: (error) => {
          PageHelpers.produceError(error, setState);
        },
        staleTime: Infinity,
      }
    );
  }
};

const useInitialValuesQuery = (state, setState, getProperties) => {
  return useQuery(['getInitialValues', getProperties, state.providerId], () => {
      const initialFields = getProperties.data || null;
      const values = {
        name: state.rowEditing?.name ? state.rowEditing.name : state.name,
        description: state.rowEditing?.description ? state.rowEditing.description : state.description,
        cron: state.rowEditing?.cron ? state.rowEditing.cron : state.cron,
        last_retrieval_date: state.rowEditing?.last_retrieval_date ? state.rowEditing.last_retrieval_date : state.last_retrieval_date,
        partition_configs: state.rowEditing?.partition_configs
          ? state.rowEditing.partition_configs
          : JSON.stringify([
              {
                label_value: "location1",
                owner_id: 12345,
              },
              {
                label_value: "location2",
                owner_id: 54321,
              },
            ], null, 2),
        ...DialogHelpers.prettifyProperties(initialFields)
      };
      return values;
    },
    {
      onError: (error) => {
        PageHelpers.produceError(error, setState);
      },
      staleTime: Infinity,
    }
  );
};

const useOwnerQuery = (method, setState) => {
  switch (method) {
    case 'GET':
      return useQuery('getOwners', async () => {
          const data = await OwnersService.getAll();
          const sortedNames = data.map(rec => rec.name).sort();
          return data
            .sort((a, b) => sortedNames.indexOf(a.name) - sortedNames.indexOf(b.name));
        },
        {
          onError: (error) => {
            PageHelpers.produceError(error, setState);
          },
          staleTime: Infinity
        }
      );
  }
};

const useProviderQuery = (method, setState) => {
  return useQuery('getProviders', async () => {
      const data = await ProviderService.getAll();
      const sortedNames = data.map(rec => rec.name).sort();
      return data
        .sort((a, b) => sortedNames.indexOf(a.name) - sortedNames.indexOf(b.name));
    },
    {
      onError: (error) => {
        PageHelpers.produceError(error, setState);
      },
      staleTime: Infinity
    }
  );
};

const getMatchingProvider = (providerArray, id) => {
  const provider = providerArray.filter(obj => obj.id === id);
  return provider[0];
}

const usePropertiesQuery = (state, setState, getProviders) => {
  return useQuery(['getProperties', getProviders, state.providerId, state.rowEditing], async () => {
      if (!getProviders.data || !state.providerId) return [];

      // Retrieve the full list of properties from the selected provider's schema.
      // These define the text fields that will be added to the form.
      const provider = getMatchingProvider(getProviders.data, state.providerId);
      const providerSchema = provider.schema;
      const schemaProperties = Object.keys(providerSchema.properties);

      // Retrieve the actual properties from the selected provider.
      // These will be the values that initially fill in the text fields created above.
      const defaultProperties = provider.properties;

      // Retrieve the specific instance/override of the properties from the data stream itself
      let dataStreamProperties = {};

      if (state.editMode === 'edit') dataStreamProperties = state.rowEditing?.data_stream_provider_properties;

      // Return a merged form of the above lists.
      // Fields are defined by the schema. The initial values are the provider's properties. And these are overridden by
      // the data stream's properties.
      return schemaProperties.map(prop => {
        let type = '';
        let description = '';
        let required = (providerSchema.required?.includes(prop) === true);

        const schemaProperties = providerSchema.properties[prop]
        if (schemaProperties) {
          type = schemaProperties.type;
          description = schemaProperties.description;
        }
        let property = {
          name: prop,
          value: '',
          type: type,
          description: description,
          required: required
        };
        if (defaultProperties[prop]) {
          property = {
            name: prop,
            value: defaultProperties[prop],
            type: type,
            description: description,
            required: required
          };
        }
        if (state.editMode === 'create') return property;
        if (dataStreamProperties[prop] && state.rowEditing.provider_id === state.providerId) {
          if (dataStreamProperties[prop] !== '') {
            property = {
              name: prop,
              value: dataStreamProperties[prop],
              type: type,
              description: description,
              required: required
            };
          }
        }
        return property;
      });
    },
    {
      onError: (error) => {
        return PageHelpers.produceError(error, setState);
      },
      staleTime: Infinity
    }
  );
};

export default {useConsumerQuery, useInitialValuesQuery, useOwnerQuery, useProviderQuery, usePropertiesQuery};