import React, {useEffect, useState} from 'react';
import {useQueryClient, useQuery} from 'react-query';
import {Formik} from 'formik';
import {Button, Dialog, DialogTitle, Grid, TextField} from '@mui/material';
import DataStreamCron from '../data-stream-cron/DataStreamCron';
import DataStreamDropDown from '../data-stream-dropdown/DataStreamDropdown';
import PageHooks from '../hooks';
import DialogHooks from './hooks';
import PageHandlers from '../handlers';
import DialogHandlers from './handlers';
import OwnerAssociationService from '../../../../apis/OwnerAssociationService';

const DataStreamEditDialog = ({state, setState}) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const refetch = async () => {
      await queryClient.refetchQueries("getProperties");
    };
    refetch();
  }, [state.providerId, queryClient]);

  const { data: childOwners = [] } = useQuery(
    ['childOwners', state.ownerId],
    async () => {
      if (!state.ownerId) return [];
      return await OwnerAssociationService.getOneByParentId(state.ownerId);
    },
    {
      enabled: Boolean(state.ownerId),
    }
  );

  const postDataStream = PageHooks.useDataStreamQuery('POST', queryClient, state, setState);
  const putDataStream = PageHooks.useDataStreamQuery('PUT', queryClient, state, setState);

  const getConsumers = DialogHooks.useConsumerQuery('GET', setState);
  const getProviders = DialogHooks.useProviderQuery('GET', setState);
  const getOwners = DialogHooks.useOwnerQuery('GET', setState);
  const getProperties = DialogHooks.usePropertiesQuery(state, setState, getProviders);
  const getInitialValues = DialogHooks.useInitialValuesQuery(state, setState, getProperties);

  const resetHWM = (values) => {
    values.last_retrieval_date = '';
    setState.setLastRetrievalDate('');
  }

  const showPartitionConfigs = () => {
    const provider = getProviders.data?.find(p => p.id === state.providerId);
    const owner = getOwners.data?.find(o => o.id === state.ownerId);
  
    return Boolean(provider?.multi_tenant_enabled) 
      && Boolean(owner?.multi_site) 
      && childOwners.length > 0;
  };

  return (
    <Dialog
      open={state.editOpen}
      onClose={() => DialogHandlers.handleClose(setState)}
      maxWidth='md'
      fullWidth={true}
    >
      <DialogTitle id='alert-dialog-title'>
        {
          state.editMode === 'edit' ? `Edit Data Stream ${state.rowEditing.id}` :
            state.editMode === 'create' ? 'Create Data Stream' : ''
        }
      </DialogTitle>
      {
        !getInitialValues.data ?
          null :
          <Formik
            initialValues={getInitialValues.data}

            validate={values => DialogHandlers.validate(values, getProperties)}

            onSubmit={state.editMode === 'edit' ? (values) => PageHandlers.handleEditSubmit(values,
              queryClient, state, setState, putDataStream, getProperties.data) :
              state.editMode === 'create' ? (values) => PageHandlers.handleCreateSubmit(values, state,
                setState, state.dataStreamFields, postDataStream, getProperties.data) :
                () => {}}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                resetForm,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <div style={{margin: '2%'}}>
                  <Grid container spacing={2}>
                    {/* ##########################[ Row 1 ]############################## */}
                    <Grid item xs={6}>
                      <TextField
                        name='name'
                        label='Name'
                        value={values.name}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row 2 ]############################## */}
                    <Grid item xs={6}>
                      <TextField
                        name='description'
                        label='Description'
                        value={values.description}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                        fullWidth
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row 3 ]############################## */}
                    <Grid item xs={6}>
                      <DataStreamDropDown
                        fieldName='owner'
                        dataList={!getOwners.data ? null : getOwners.data}
                        value={state.ownerId || ''}
                        handleChange={(selection) => DialogHandlers.handleOwnerSelect(selection, setState)}
                        isMultiple={false}
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row 4 ]############################## */}
                    <Grid item xs={6}>
                      <DataStreamDropDown
                        fieldName='provider'
                        dataList={!getProviders.data ? null : getProviders.data}
                        value={state.providerId || ''}
                        handleChange={(selection) => DialogHandlers.handleProviderSelect(selection, values, resetForm,
                          setState, getProperties)}
                        isMultiple={false}
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Row 5 thru x ]############################## */}
                    {
                      !getProperties.data ?
                        null :
                        getProperties.data.map(field => (
                          <>
                            <Grid item xs={6} key={field.name}>
                              <TextField
                                name={field.name}
                                label={field.name}
                                value={values[field.name]}
                                error={Boolean(touched[field.name] && errors[field.name])}
                                helperText={touched[field.name] && errors[field.name]}
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant='outlined'
                              />
                            </Grid>
                            <Grid item xs={6}/>
                          </>
                        ))
                    }
                    {/* ##########################[ Partition Configs Row ]############################## */}
                    {
                      showPartitionConfigs() && (
                        <>
                        <Grid item xs={6}>
                          <TextField
                            name='partition_configs'
                            label='Partition Configs'
                            value={
                              typeof values.partition_configs === "object"
                                ? JSON.stringify(values.partition_configs, null, 2)
                                : values.partition_configs
                            }
                            error={Boolean(touched.partition_configs && errors.partition_configs)}
                            helperText={touched.partition_configs && errors.partition_configs}
                            fullWidth
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant='outlined'
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item xs={6}/>
                        </>
                      )
                    }
                    {/* ##########################[ Row (x+1) ]############################## */}
                    <Grid item xs={6}>
                      <DataStreamDropDown
                        fieldName='consumers'
                        dataList={!getConsumers.data ? null : getConsumers.data}
                        value={state.consumerId || []}
                        handleChange={(selection) => DialogHandlers.handleConsumerSelect(selection, state, setState)}
                        isMultiple={true}
                      />
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ Last Retrieval Date ROW ]############################## */}
                    <Grid item xs={6}>
                      <TextField
                        name='last_retrieval_date'
                        label='Last Retrieval Date'
                        value={values.last_retrieval_date}
                        error={Boolean(touched.last_retrieval_date && errors.last_retrieval_date)}
                        helperText={touched.last_retrieval_date && errors.last_retrieval_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant='outlined'
                        inputProps={{ readOnly: true }}
                      />
                      &nbsp;
                      <Button
                        color='primary'
                        onClick={() => resetHWM(values)}
                        size='large'
                        type='button'
                        variant='outlined'
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item xs={6}/>
                    {/* ##########################[ CRON ROW ]############################## */}
                    <Grid item xs={12}>
                      <DataStreamCron
                        fieldName='cron'
                        handleChange={(value) => DialogHandlers.handleCronUpdate(value, setState)}
                        cronValue={state.cron}
                        errors={errors}
                      />
                    </Grid>
                  {/* ##########################[ Submit/Cancel Row ]############################## */}
                    <Grid item xs={12}>
                      <div>
                        <Button
                          color='primary'
                          disabled={isSubmitting}
                          onClick={() => DialogHandlers.handleClose(setState)}
                          size='large'
                          type='button'
                          variant='outlined'
                        >
                          Cancel
                        </Button>
                        &nbsp;
                        <Button
                          color='primary'
                          disabled={isSubmitting || !state.ownerId || !state.providerId ||
                            !state.consumerId || (!state.consumerId.length) ||
                            Object.keys(errors).length !== 0 || !values.name}
                          size='large'
                          type='submit'
                          variant='contained'
                        >
                          Save
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </form>
            )}
          </Formik>
      }
    </Dialog>
  );
};

export default DataStreamEditDialog;